import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ImgLoadErrDirective } from './imgloaderr.directive';
import { ProfileImgLoadErrDirective } from './profileimgloaderr.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ImgLoadErrDirective, ProfileImgLoadErrDirective],
  exports: [ImgLoadErrDirective, ProfileImgLoadErrDirective],
})
export class FrontendSharedUtilImageModule {}

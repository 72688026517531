import { Directive, HostListener, Input } from '@angular/core';
import { imageLoadErrUrl } from '@memberspot/shared/util/constants';

const isImage = (
  target: HTMLElement | HTMLImageElement | null | EventTarget,
): target is HTMLImageElement => (target ? 'src' in target : false);

@Directive({
  selector: '[mspotImgLoadErr]',
})
export class ImgLoadErrDirective {
  loadErr = false;

  @Input('mspotImgLoadErr') placeholder = imageLoadErrUrl;

  constructor() {}

  @HostListener('error', ['$event'])
  imgLoadFailed(event: Event) {
    if (!this.loadErr && isImage(event?.target)) {
      this.loadErr = true;
      event.target.src = this.placeholder || imageLoadErrUrl;
    }
  }
}

import { Directive } from '@angular/core';
import { exampleHeaderProfileSlotUrl } from '@memberspot/shared/util/constants';

import { ImgLoadErrDirective } from './imgloaderr.directive';

@Directive({
  selector: '[mspotProfileImgLoadErr]',
})
export class ProfileImgLoadErrDirective extends ImgLoadErrDirective {
  override placeholder = exampleHeaderProfileSlotUrl;
}
